import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import GridHome from '../../components/gridHome/GridHome';
import './Home.scss';

// // // import FeaturesContent from '../../components/featuresContent/FeaturesContent';
// import FeaturesSection from '../featuresSection/FeaturesSection';

export const Home = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <Row className="RootHome">
      <div className="RootHome-inside" style={{ zIndex: '99' }}>
        <GridHome show={show} onHide={handleClose} onShow={handleShow} />
      </div>
    </Row>
  );
};

export default Home;
