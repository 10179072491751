import React from "react";
import { Button } from "react-bootstrap";
import "./ArrowBack.scss";
import { Link } from "react-router-dom";

//FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//ICON
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const ArrowBack = (props) => {
  // Button HIDDEN ON SCROLL //
  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("buttonBack").style.top = "-50px";
    } else {
      document.getElementById("buttonBack").style.top = "-127px";
    }
    prevScrollpos = currentScrollPos;
  };
  return (
    <div>
      <Link
        to={(location) => ({
          ...location,
          pathname: "/home",
        })}
      >
        <Button id="buttonBack" className="backButton">
          <FontAwesomeIcon className="socialIconStyle" icon={faArrowLeft} />
        </Button>
      </Link>
    </div>
  );
};

export default ArrowBack;
