import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import TimeLine from './components/TimeLine';

import { Parallax } from 'react-scroll-parallax';
import { isMobile, isPromoActive, isBlackFriday } from '../../../../layout/Layout';
import bflogo from '../../../../assets/img/blackfriday/bflogo.png';

const EarnSection = () => {
  const mobile = isMobile();

  return (
    <Row className="earnSectionRow h-100 justify-content-center align-items-center">
      <Col xs={12} lg={6} xl={6} data-aos="fade-up" data-aos-duration="1000">
        <h2 className="h2Title">
          ¿Qué conseguiré con
          <br /> <span>Twerkealo</span>?
        </h2>
        <div className="d-none d-lg-block">
          <TimeLine />
        </div>
      </Col>
      <Col
        xs={12}
        lg={6}
        xl={6}
        className="earnContent"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Parallax
          className="custom-class"
          y={[-20, 20]}
          tagOuter="figure"
          disabled={mobile}
        >
          <img
            alt=""
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="earnImg d-inline d-lg-none"
            src={require('../../../../assets/img/photos/4---Twerkealo-min-min.png')}
          ></img>
          <img src={bflogo} style={{display: (isPromoActive() && isBlackFriday) ? '' : 'none', maxWidth: '60%', marginBottom: '40px'}} />
          <p className="pText">
            Los beneficios de Twerk son sencillamente INCONTABLES. Te permite
            expresar tus sentimientos y transmitir tus emociones cuando lo
            practicas. Una sensación de empoderamiento liberadora que te ayuda
            tanto física como emocionalmente. <br />
            <br />
            No es un secreto que el baile te ayuda a liberar endorfinas, es
            decir ser más FELIZ. Con el Twerk todo se potencia mucho más, no
            solo es felicidad lo que consigues, también seguridad, confianza y
            poder.
          </p>
          <img
            alt=""
            data-aos="fade-down"
            data-aos-duration="1000"
            data-aos-offset="-400"
            className="earnImg d-none d-lg-inline"
            src={require('../../../../assets/img/photos/4---Twerkealo-min-min.png')}
            style={{ marginTop: '50px' }}
          ></img>
          <div className="d-inline d-lg-none">
            <TimeLine />
          </div>
          <Button
            bsPrefix
            href="#inscribirse"
            className="buttonImage earnButton"
          >
            Quiero empezar a Twerkear
          </Button>
        </Parallax>
      </Col>
    </Row>
  );
};

export default EarnSection;
