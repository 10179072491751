import { discounts } from './discounts.json';
import { isPromoActive } from '../../layout/Layout';

/****************************************************************
 *
 * @Author Peter Palmer
 * @Objective This function returns a discount object if the name
 * of it exists on the url parameter: promcode
 *
 ***************************************************************/

const promcodeHasExpired = (discount) => {
  if (discount.expiry_date.length > 0) {
    return Date.parse(discount.expiry_date) < new Date();
  }
  return false;
};

const promcodeIsActive = (discount) => {
  if (discount.activation_date.length > 0) {
    return Date.parse(discount.activation_date) < new Date();
  }
  return true;
};

const validatePromCode = () => {
  // Verify that the url contanis the discount
  const promcode = new URLSearchParams(window.location.search).get('promcode');
  if (promcode && discounts.length > 0) {
    for (let discount of Object.values(discounts)) {
      if (discount.discount_name === promcode) {
        if (
          discount.activation_date.length === 0 &&
          discount.expiry_date.length === 0
        ) {
          if (discount.active) {
            return discount;
          }
          return discounts[0];
        }
        if (promcodeIsActive(discount) && !promcodeHasExpired(discount)) {
          return discount;
        }
        return discounts[0];
      }
    }
  }

  // Return default discount
  if (isPromoActive()) {
    return discounts[1];
  } else {
    return discounts[0];
  }
};

export default validatePromCode;
