import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from '../views/Main';
// import Maintenance from "../Maintenance";
import PoliticaDePrivacidad from '../views/pages/privacity/PoliticaDePrivacidad';
import PoliticaDeCookies from '../views/pages/cookies/PoliticaDeCookies';
import TerminosYCondiciones from '../views/pages/terms/TerminosYCondiciones';
import Exclusion from '../views/pages/exclusion/Exclusion';

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />

        <Route
          path="/politica-de-privacidad"
          exact
          render={(props) => <PoliticaDePrivacidad {...props} />}
        />
        <Route
          path="/politica-de-cookies"
          exact
          render={(props) => <PoliticaDeCookies {...props} />}
        />
        <Route
          path="/aviso-legal"
          exact
          render={(props) => <TerminosYCondiciones {...props} />}
        />
        <Route
          path="/exclusion-de-responsabilidad"
          exact
          render={(props) => <Exclusion {...props} />}
        />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
