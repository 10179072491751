import React from 'react';
import './Faq.scss';
import FaqSection from '../../components/faqSection/FaqSection';

export const Faq = () => {
  return (
    <div className="RootFaq">
      <div className="fluid RootFaq-inside" id="faq">
        <div className="">
          <FaqSection />
        </div>
      </div>
    </div>
  );
};

export default Faq;
