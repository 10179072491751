import React from 'react';
import { Container } from 'react-bootstrap';
import JoinUsSection from '../../components/joinUsSection/JoinUsSection';
import './JoinUs.scss';

export const JoinUs = (props) => {
  return (
    <Container className="text-center RootJoinUs">
      <div className="RootJoinUs-inside h-100" style={{ zIndex: '90' }}>
        <JoinUsSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default JoinUs;
