import React, { Suspense, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Parallax } from 'react-scroll-parallax';
import { isMobile, PlayerTogglerContext } from '../../../../layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const Flickity = React.lazy(() => import('react-flickity-component'));

const TestimonialsSection = () => {
  const PlayerToggler = useContext(PlayerTogglerContext);

  const mobile = isMobile();

  const options = {
    pageDots: true,
    autoPlay: 1500,
    initialIndex: 0,
    prevNextButtons: isMobile() ? false : true,
    cellAlign: 'right',
    wrapAround: true,
    reloadOnUpdate: true,
  };

  return (
    <Row
      className="testimonialsSectionRow h-100 justify-content-center align-items-center"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <Col xs={12}>
        <h1 className="testimoniosSectionTitle">
          <p className="h2Title">
            Twerk <span className="boldText">VIP Club</span>
          </p>
        </h1>
      </Col>
      <Col xs={12} lg={12} className="testimonialsText">
        <Parallax
          className="custom-class"
          // x={[-20, 0]}
          tagOuter="figure"
          disabled={mobile}
        >
          <Suspense fallback={<div></div>}>
            <Flickity
              className="mb-5"
              elementType={'div'} // default 'div'
              options={options} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={true} // default false
              static={true} // default false
            >
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811449?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test1-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test1-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811488?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test2-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test2-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811524?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test3-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test3-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811561?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test4-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test4-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811406?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    {/* <source
                    type="image/webp"
                    srcSet={require('../../../../assets/img/photos/testimonials/test5-min.webp')}
                  /> */}

                    <img
                      src={require('../../../../assets/img/photos/testimonials/test5-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811590?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    <img
                      src={require('../../../../assets/img/photos/testimonials/test6-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 carouselImgs px-1"
                onClick={() => {
                  PlayerToggler.setToggler({
                    opened: !PlayerToggler.toggler,
                    src: 'https://player.vimeo.com/video/568811622?autoplay=1',
                  });
                }}
              >
                <div className="carouselImgsContainer">
                  <div className="playContainer">
                    <FontAwesomeIcon icon={faPlay} className="playIcon" />
                  </div>
                  <picture>
                    <img
                      src={require('../../../../assets/img/photos/testimonials/test7-min.png')}
                      alt="Olvia Misssy Transformaciones"
                      className="img-fluid flickityImages"
                    />
                  </picture>
                </div>
              </div>
            </Flickity>
          </Suspense>
        </Parallax>
      </Col>
    </Row>
  );
};

export default TestimonialsSection;
