import React from 'react';
import { Container } from 'react-bootstrap';
import TestimonialsSection from '../../components/testimonialsSection/TestimonialsSection';

export const Testimonials = (props) => {
  return (
    <Container className="text-center RootTestimonials" id="vipClub">
      <div className="RootTestimonials-inside h-100" style={{ zIndex: '90' }}>
        <TestimonialsSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Testimonials;
