import React, { Suspense } from 'react';
import { useSpring } from 'react-spring';

import { Container } from 'react-bootstrap';

// PAGES
import Layout from '../layout/Layout';
import Home from './landing/pages/homeTop/Home';
import Video from './landing/pages/video/Video';
import Unique from './landing/pages/unique/Unique';
import Earn from './landing/pages/earn/Earn';
import ForMe from './landing/pages/forMe/ForMe';
import AboutUs from './landing/pages/aboutUs/AboutUs';
import Program from './landing/pages/program/Program';
import Testimonials from './landing/pages/testimonials/Testimonials';
import Prices from './landing/pages/prices/Prices';
import JoinUs from './landing/pages/joinUs/JoinUs';
import Faq from './landing/pages/faq/Faq';
import ExitPopUp from '../components/exitPopUp/ExitPopUp';
import Footer from '../components/footer/Footer';

// const Video = React.lazy(() => import('./landing/pages/video/Video'));
// const Unique = React.lazy(() => import('./landing/pages/unique/Unique'));
// // const Earn = React.lazy(() => import('./landing/pages/earn/Earn'));
// const ForMe = React.lazy(() => import('./landing/pages/forMe/ForMe'));
// const AboutUs = React.lazy(() => import('./landing/pages/aboutUs/AboutUs'));
// const Program = React.lazy(() => import('./landing/pages/program/Program'));
// const Prices = React.lazy(() => import('./landing/pages/prices/Prices'));
// const JoinUs = React.lazy(() => import('./landing/pages/joinUs/JoinUs'));
// const Faq = React.lazy(() => import('./landing/pages/faq/Faq'));
// const Footer = React.lazy(() => import('../components/footer/Footer'));

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const Main = (props) => {
  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];
  const [properties, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <Layout>
      <Container
        fluid
        id="inicio"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <div className="textureBack" />
        <Home />
        <Video />
        <Unique />
        <Earn />
        <ForMe />
        <AboutUs />
        <Program />
        <Testimonials />
        <Prices />
        <Faq />
        <JoinUs />
        {/* <ExitPopUp /> */}
        <Footer />
      </Container>
    </Layout>
  );
};

export default Main;
