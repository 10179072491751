import React, { createContext, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Cursor from '../components/cursor/Cursor';
import Canvas from '../components/canvas/Canvas';
import Navbar from '../components/navbar/Navbar';
import FsLightbox from 'fslightbox-react';
import DeadLinePromo from '../components/countDownPromo/DeadLinePromo';

// STYLE
import './Layout.css';

// Libraries
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';

// const imagemin = require('imagemin');
// const imageminPngquant = require('imagemin-pngquant');
export const PlayerTogglerContext = createContext(null);

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const PromoDates = {
  dateActive: new Date('2021-11-22T14:00:00+01:00'),
  dateExpire: new Date('2021-11-29T23:59:00+01:00'),
  todayDate: new Date(),
};
export const isPromoActiveTimer = () => {
  return {
    isActive: !localStorage.getItem('promoTimerCompleted'),
    timer: 172800000, // 172800000
  };
};
export const isPromoActive = () => {
  const dateActive = PromoDates.dateActive;
  const dateExpire = PromoDates.dateExpire;
  const todayDate = PromoDates.todayDate;
  if((new URLSearchParams(window.location.search).get('chkprm')) == 1) return true;
  return dateActive < todayDate && dateExpire > todayDate;
};

export const isBlackFriday = false;

export const Layout = (props) => {
  useEffect(() => {
    AOS.init({ disable: window.innerWidth < 991 });
    new SmoothScroll('a[href*="#"]', { offset: 50 });
  });

  // (async () => {
  //   const files = await imagemin(['images/*.{png}'], {
  //     destination: 'build/static/media',
  //     plugins: [
  //       imageminPngquant({
  //         quality: [0.6, 0.8],
  //       }),
  //     ],
  //   });

  //   console.log(files);
  //   //=> [{data: <Buffer 89 50 4e …>, destinationPath: 'build/images/foo.jpg'}, …]
  // })();
    
  const [toggler, setToggler] = useState({
    opened: false,
    src: null,
  });
  let PlayerToggler = useRef(setToggler);
  const getPlayerToggler = () => {
    return PlayerToggler.current;
  };
  return (
    <PlayerTogglerContext.Provider
      value={{ getPlayerToggler, toggler, setToggler }}
    >
      {isPromoActive() ? (
        <DeadLinePromo />
      ) : (
        ''
      )}
      <Container
        nogutters="true"
        className={
          isPromoActive() ? 'inicioPromo' : ''
        }
        fluid
        id="inicio"
      >
        {typeof navigator !== 'undefined' && isMobile() ? null : <Canvas />}

        <Navbar />
        {typeof navigator !== 'undefined' && isMobile() ? null : <Cursor />}
        {props.children}
        <FsLightbox
          toggler={toggler}
          sources={[
            <iframe
              src={toggler.src}
              width="1920"
              height="1080"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="VSL - NFT MASTERY"
            ></iframe>,
          ]}
          type="video"
        />
      </Container>
    </PlayerTogglerContext.Provider>
  );
};

export default Layout;
