import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { isPromoActive, isBlackFriday } from '../../../../layout/Layout';
import bflogo from '../../../../assets/img/blackfriday/bflogo.png';

const ProgramSection = () => {
  return (
    <Row
      className="programSectionRow h-100"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <Col xs={12} lg={6} className="programColImg">
        <img src={bflogo} style={{display: (isPromoActive() && isBlackFriday) ? '' : 'none', maxWidth: '50%', marginBottom: '40px'}} />
        <h2 className="h2Title">PROGRAMA DEL CURSO</h2>

        <img
          alt=""
          className="programImg"
          src={require('../../../../assets/img/photos/8---Twerkealo-min-min.png')}
        ></img>
      </Col>
      <Col xs={12} lg={4} className="programContent" md={{ offset: '1' }}>
        <div
          className="pText textSquare"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 className="h3Title">
            Módulo 1: Introducción e Historia del Twerk
          </h3>
          <p className="pText">
            Aprenderás el porqué de este maravilloso baile, de dónde viene, cómo
            nació y los aspectos básicos para dominarlo. Te daremos consejos
            para adentrarte y aprender del mundo de la noche, como iniciar en
            esta apasionante área y poner en práctica todo lo aprendido en este
            programa.
          </p>
          <div className="topLine"></div>
          <div className="rightLine"></div>
          <div className="leftLine"></div>
          <div className="bottomLine"></div>
        </div>
        <div
          className="pText textSquare"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 className="h3Title">
            Módulo 2: Pasos y Coreografías NIVEL FÁCIL
          </h3>
          <p className="pText">
            ¿Nunca has hecho Twerk? ¿Vienes con un Nivel Cero? No te preocupes,
            es este nivel aprenderás todo lo básico y los pasos más sencillos
            para poco a poco aprender sobre este estilo de baile. Paso a paso te
            guiaremos por los inicios para que tengas una base sólida para pasar
            a niveles más avanzados. Si ya has hecho Twerk antes, este módulo te
            ayudará a perfeccionar esos pasos que quizás no has logrado dominar.
          </p>
          <div className="topLine"></div>
          <div className="rightLine"></div>
          <div className="leftLine"></div>
          <div className="bottomLine"></div>
        </div>
        <div
          className="pText textSquare"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 className="h3Title">
            Módulo 3: Pasos y Coreografías NIVEL INTERMEDIO
          </h3>
          <p className="pText">
            Ya nos vamos adentrando mucho más en el Twerk. Iremos profundizando
            en la dificultad, pero siempre adaptado a ti y a todas. No hay
            límites, con este nivel ya vas aprendiendo pasos más profesionales,
            que te servirán para ir ganando más y más seguridad al bailar.
          </p>
          <div className="topLine"></div>
          <div className="rightLine"></div>
          <div className="leftLine"></div>
          <div className="bottomLine"></div>
        </div>
        <div
          className="pText textSquare"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <h3 className="h3Title">
            Módulo 4: Pasos y Coreografías NIVEL AVANZADO
          </h3>
          <p className="pText">
            Llegamos al TOP. Aquí ponemos toda la carne (booty) en el asador.
            Aprenderás pasos más avanzados y coreografías más profesionales. Con
            este nivel definitivamente dejarás boquiabiertos a todos e incluso,
            con mucha práctica llegar enseñarle Twerk a quien quieras. En este
            nivel tu seguridad y sensualidad estarás A TOPE, sólo te hará falta
            música para que tu culo despierte y haga magia.
          </p>
          <div className="topLine"></div>
          <div className="rightLine"></div>
          <div className="leftLine"></div>
          <div className="bottomLine"></div>
        </div>
      </Col>
    </Row>
  );
};

export default ProgramSection;
