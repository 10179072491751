import React from 'react';
import { Container } from 'react-bootstrap';
import ProgramSection from '../../components/programSection/ProgramSection';
import './Program.scss';

export const Program = (props) => {
  return (
    <Container className="text-center RootProgram" id="programa">
      <div className="RootProgram-inside h-100" style={{ zIndex: '90' }}>
        <ProgramSection properties={props.properties} />
      </div>
    </Container>
  );
};

export default Program;
