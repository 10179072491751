import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const ForMeSection = () => {
  return (
    <Row className="ForMeSectionRow h-100 justify-content-center">
      <Col xs={12} lg={6}>
        <h2 className="h2Title" data-aos="fade-right" data-aos-duration="1000">
          ¿Este programa es <span className="boldText">para mi</span>?
        </h2>

        <p
          className="pText forMeSubtitle"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          Tendrás 3 módulos, fácil-intermedio-avanzado para que aprendas por
          completo el twerk hasta que seas una maestra moviendo el culo al ritmo
          que te pongan. Te garantizamos diversión, pero también que será
          efectivo, en poco tiempo dominarás este apasionante baile.
        </p>
        <div data-aos="fade-up" data-aos-duration="1000">
          <img
            alt=""
            className="forMeImg"
            src={require('../../../../assets/img/photos/5---Twerkealo-min-min.png')}
          ></img>
        </div>
      </Col>
      <Col
        xs={12}
        lg={(4, { offset: 1 })}
        className="forMeContent"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div className="stickyTopNav">
          <h2 className="h3Title">Twerkealo es para ti si:</h2>
          <p className="pText">
            Has intentado hacer Twerk anteriormente y te han hecho sentir guarra
            o fuera de lugar. Demuestra con Twerkealo que NO es lo que piensan.
          </p>
          <div className="whiteLine"></div>
          <p className="pText">
            Has intentado aprender con videos y tutoriales que encuentras en las
            redes, pero ninguno lo hace de forma profesional.
          </p>
          <div className="whiteLine"></div>
          <p className="pText">
            Quieres dominar un estilo de baile que potenciará tu sensualidad sin
            prestar atención al qué dirán.
          </p>
          <div className="whiteLine"></div>
          <p className="pText">
            Te apasiona el baile, pero no te has atrevido a intentarlo o piensas
            que es muy difícil.
          </p>
          <div className="whiteLine"></div>
        </div>
      </Col>
      <Col xs={12} className="uniqueColButton">
        <Button href="#faq" bsPrefix className="buttonsUnique buttonImage">
          <span className="buttonTitle">Resuelve tus dudas aquí</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ForMeSection;
