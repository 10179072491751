import React from 'react';
import { Row, Col, ListGroup, Container } from 'react-bootstrap';
import './Footer.scss';

import { Link } from 'react-router-dom';
import { PoweredBySuprive } from '../powered/PoweredBySuprive';

export const Footer = (props) => {
  return (
    <Container className="RootFooter">
      <Row className="RootFooter-inside">
        <Col className="col-12 col-lg-4 brandFooter">
          <div className="logoFooter">
            <img
              alt=""
              src={require('../../assets/img/icons/Logo---Twerkealo.svg')}
              className="brandLogo"
            />
          </div>
        </Col>
        <Col className="col-12 col-lg-4 footerLinks d-none d-lg-inline">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle">
              enlaces de interés
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#beneficios">Características del curso</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#que-conseguire">Qué conseguiré</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#programa">Módulos del curso</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#inscribirse">Precios</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#faq">Preguntas Frecuentes</a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        {/* <Col xs={1}></Col> */}
        <Col className="col-12 col-lg-4 footerLinks d-none d-lg-inline">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle">
              legal
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/politica-de-privacidad',
                })}
              >
                Política de Privacidad
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/politica-de-cookies',
                })}
              >
                Política de Cookies
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/aviso-legal',
                })}
              >
                Aviso Legal
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/exclusion-de-responsabilidad',
                })}
              >
                Exclusión de responsabilidad
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText powered">
              <PoweredBySuprive />
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col className="col-12 col-lg-4 footerLinks d-inline d-lg-none">
          <ListGroup className="listGroupFooter">
            <ListGroup.Item className="listGroupItemFooter listGroupTitle">
              enlaces de interés
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#beneficios">Características del curso</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#que-conseguire">Qué conseguiré</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#programa">Módulos del curso</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#inscribirse">Precios</a>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <a href="#faq">Preguntas Frecuentes</a>
            </ListGroup.Item>

            <ListGroup.Item className="listGroupItemFooter listGroupTitle legalContainer">
              legal
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/politica-de-privacidad',
                })}
              >
                Política de Privacidad
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/politica-de-cookies',
                })}
              >
                Política de Cookies
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/aviso-legal',
                })}
              >
                Aviso Legal
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <Link
                target="_blank"
                to={(location) => ({
                  ...location,
                  pathname: '/exclusion-de-responsabilidad',
                })}
              >
                Exclusión de responsabilidad
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="listGroupItemFooter pText listGroupText">
              <PoweredBySuprive />
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} className="footerColFace">
          <p className="footerFaceText pText">
            Esta web no pertenece a Facebook o Meta Inc. y tampoco está
            respaldada por Facebook de ninguna manera. Facebook proporciona una
            plataforma para ofrecer publicidad, pero los contenidos ofrecidos en
            esta web no son representativos de Facebook Inc.
            <br />
            <br />
            This website does not belong to Facebook or Meta Inc. and is not
            endorsed by Facebook in any way. Facebook provides a platform to
            offer advertising, but the content offered on this website is not
            representative of Facebook Inc.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
export default Footer;
