import React from 'react';
import { isPromoActiveTimer } from '../../layout/Layout';
import CountDownPromo from './CountDownPromo';
import CountDownPromoTimer from './countDownTimer/CountDownPromo';
import { isBlackFriday } from '../../layout/Layout';
import party from '../../assets/img/blackfriday/party.png';

const DeadLinePromo = () => {
  return (
    <div className="deadLineFunnelCustom">
      <div className="deadLineWrapper">
        <h4 style={{display: (isBlackFriday) ? 'none' : ''}}>Tu oferta acaba en...</h4>
        <h4 style={{display: (isBlackFriday) ? '' : 'none'}}><img src={party} /> OFERTA BLACK FRIDAY...</h4>
        {isPromoActiveTimer().isActive ? (
          <CountDownPromoTimer />
        ) : (
          <CountDownPromo />
        )}
      </div>
    </div>
  );
};

export default DeadLinePromo;
