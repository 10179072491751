import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

export const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hidden, setHidden] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [linkHovered, setLinkHovered] = useState(false);
  const [imgHovered, setImgHovered] = useState(false);

  useEffect(() => {
    addEventListeners();
    handleLinkHoverEvents();
    return () => removeEventListeners();
  }, []);

  const addEventListeners = () => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseenter', onMouseEnter);
    document.addEventListener('mouseleave', onMouseLeave);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
  };

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseenter', onMouseEnter);
    document.removeEventListener('mouseleave', onMouseLeave);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    setClicked(true);
  };

  const onMouseUp = () => {
    setClicked(false);
  };

  const onMouseLeave = () => {
    setHidden(true);
  };

  const onMouseEnter = () => {
    setHidden(false);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.pageX, y: e.pageY });
  };

  const handleLinkHoverEvents = () => {
    document.querySelectorAll('a').forEach((el) => {
      el.addEventListener('mouseover', () => setLinkHovered(true));
      el.addEventListener('mouseout', () => setLinkHovered(false));
    });
    document.querySelectorAll('img').forEach((el) => {
      el.addEventListener('mouseover', () => setImgHovered(true));
      el.addEventListener('mouseout', () => setImgHovered(false));
    });
    document.querySelectorAll('button').forEach((el) => {
      el.addEventListener('mouseover', () => setImgHovered(true));
      el.addEventListener('mouseout', () => setImgHovered(false));
    });
    document.querySelectorAll('span').forEach((el) => {
      el.addEventListener('mouseover', () => setImgHovered(true));
      el.addEventListener('mouseout', () => setImgHovered(false));
    });
    document.querySelectorAll('submit').forEach((el) => {
      el.addEventListener('mouseover', () => setImgHovered(true));
      el.addEventListener('mouseout', () => setImgHovered(false));
    });
    document.querySelectorAll('.popUpLogo').forEach((el) => {
      el.addEventListener('mouseover', () => setImgHovered(true));
      el.addEventListener('mouseout', () => setImgHovered(false));
    });
    // document
    //   .getElementById('_form_142_submit')
    //   .addEventListener('mouseover', () => setImgHovered(true));
  };

  const cursorClasses = classNames('cursor', {
    'cursor--clicked': clicked,
    'cursor--hidden': hidden,
    'cursor--link-hovered': linkHovered,
    'cursor--img-hovered': imgHovered,
  });

  return (
    <div
      className={cursorClasses}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
    ></div>
  );
};
export default Cursor;
