import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PricesSection from '../../components/pricesSection/PricesSection';
import './Prices.scss';
import validatePromCode from '../../../../assets/js/discounts.js';

export const Prices = () => {
  const [discount, setDiscount] = useState({});
  useEffect(() => {
    const discountData = validatePromCode();
    if (discountData) {
      setDiscount(discountData);
    }
  }, []);
  return (
    <Container className="text-center RootPrices">
      <div className="RootPrices-inside h-100" style={{ zIndex: '90' }}>
        <PricesSection discount={discount} />
      </div>
    </Container>
  );
};

export default Prices;
